<template>
  <page v-loading="loading">
    <template slot="headerLeft">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="商品名称" prop="goodsName">
          <el-input v-model="params.goodsName" />
        </el-form-item>
        <el-form-item label="商品条码" prop="barNo">
          <el-input v-model="params.barNo" />
        </el-form-item>
        <el-form-item label="商品一级分类" prop="invoiceType">
          <el-select
            v-model="params.clazzId1"
            filterable
            clearable
            @change="getclass2Id(params.clazzId1)"
          >
            <el-option
              v-for="(item, index) in class1List"
              :key="index"
              :value="item.id"
              :label="item.clazzName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品二级分类" prop="invoiceType">
          <el-select v-model="params.clazzId2" filterable clearable>
            <el-option
              v-for="(item, index) in class2List"
              :key="index"
              :value="item.id"
              :label="item.clazzName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品品牌" prop="goodsBrand">
          <el-select v-model="params.goodsBrand" clearable>
            <el-option
              v-for="(item, index) in brandList"
              :key="index"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <br />
        <el-form-item label="库存范围" prop="stockStart">
          <el-input v-model="params.stockStart" placeholder="最小值"></el-input>
        </el-form-item>
        <el-form-item label=" ~ " prop="stockEnd">
          <el-input v-model="params.stockEnd" placeholder="最大值"></el-input>
        </el-form-item>
        <el-button type="primary" :loading="loading" @click="outExel">
          导出
        </el-button>
        <el-button type="primary" @click="openShow"> 商品盘点 </el-button>
      </el-form>
    </template>
    <!-- <div class="conter-div">
      <span>库存数量：</span>
      <span>{{ salesCount }}</span>
      <span>销售总价（元）：</span>
      <span> {{ salesAmount }}</span>
    </div> -->
    <div class="table-wrap">
      <el-table
        :data="tableData"
        border
        height="100%"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="goodsName"
          label="商品名称"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="barNo"
          label="条形码"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="specification"
          label="规格"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="brandName"
          label="品牌"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="clazzName1"
          label="一级分类"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="clazzName2"
          label="二级分类"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="sellPrice"
          label="当前零售价（元）"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="inPrice"
          label="进货平均价（元）"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="stock"
          label="商品当前库存"
        ></el-table-column>
      </el-table>
    </div>
    <el-dialog
      :destroy-on-close="true"
      :close-on-click-modal="false"
      title="提示"
      :visible.sync="show"
      width="400px"
      :loading="loading"
    >
      <div>
        您确定对当前1种商品进行盘点操作，盘点过程中将会暂时冻结当前商品库存数量，将会影响到您正常的销售、入库、调拨等操作？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="show = false">取消</el-button>
        <el-button @click="submit">确定</el-button>
      </span>
    </el-dialog>
    <pagination
      slot="pagination"
      :totalCount="totalCount"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
export default {
  mixins: [watchParamsGetData],

  data() {
    return {
      loading: false,
      groupList: [],
      tableData: [],
      class1List: [],
      class2List: [],
      brandList: [],
      salesCount: '',
      salesAmount: '',
      show: false,
      params: {
        limit: 20,
        page: 1
      },
      totalCount: 0,
      ids: []
    }
  },

  created() {
    this.getData()
    this.getClass()
    this.getBrandList()
  },
  methods: {
    async getClass() {
      try {
        const res = await this.$api.clazzList.getClazz({})
        this.class1List = res.clazzList.list
      } catch (error) {}
    },
    async getclass2Id(val) {
      try {
        const res = await this.$api.clazzList.getClazzList({
          parentId: val,
          limit: 1000,
          page: 1
        })
        if (res) {
          this.class2List = res.secondaryClazzList.list
        }
      } catch (error) {}
    },
    async getBrandList() {
      try {
        const res = await this.$api.brandList.getGoodsBrandList({
          limit: 1000,
          page: 1
        })

        this.brandList = (res.brandList.list || []).map(item => {
          return {
            label: item.brandName,
            value: item.id
          }
        })
      } catch (err) {}
    },
    async getData(query) {
      try {
        this.loading = true
        let params = { ...this.params }
        if (params.date && params.date.length) {
          params.createTimeEnd = this.$utils.dateFormat(
            params.date[1],
            'YYYY-MM-DD HH:mm:ss'
          )
          params.createTimeStart = this.$utils.dateFormat(
            params.date[0],
            'YYYY-MM-DD HH:mm:ss'
          )
        } else {
          params.createTimeEnd = undefined
          params.createTimeStart = undefined
        }
        if (query) {
          params.limit = 20
          params.page = 1
        }
        const res = await this.$api.commodity.getProductList(params)
        if (res.page && res.page.list) {
          this.tableData = res.page.list.map(item => {
            return item
          })
        }
        this.totalCount = res.page.totalCount
        this.salesCount = res.salesCount
        this.salesAmount = res.salesAmount
      } catch (err) {
      } finally {
        this.loading = false
      }
    },
    async outExel() {
      try {
        this.loading = true
        let params = { ...this.params }
        await this.$api.commodity.exportInventoryExel(params)
        this.loading = false
      } catch (error) {
      } finally {
        this.loading = false
      }
      // exportGoodExel
    },
    handleSelectionChange(val) {
      this.ids = []
      this.ids = val.map(itme => {
        return itme.id
      })
    },
    openShow() {
      if (this.ids.length == 0) {
        this.$message.warning('请选择需要盘点的商品')
        return
      } else {
        this.show = true
      }
    },
    async submit() {
      try {
        let data = { ids: this.ids }
        const res = await this.$api.commodity.postInventory(data)
        this.$router.push({
          name: 'goodsInventory'
        })
      } catch (error) {}
    }
  }
}
</script>

<style scoped lang="scss">
.conter-div {
  margin-top: 10px;
  span {
    margin-right: 20px;
  }
}
</style>
